import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Section01 from './section01/index'
import Section02 from './section02/index'
import Section03 from './section03/index'
import Section04 from './section04/index'
import Section05 from './section05/index'
import Header from '@components/NewMain/Header'
import FooterInLogo from '@components/NewMain/Footer'
import Samsung from './samsung/index'
import MobileHeader from '@components/NewMain/Mobile/Header'
import MobilePage from '../NewMobilePage/index'

const NewMainPage = () => {
  const [isMobile, setIsMobile] = useState(false)
  const userAgent = navigator.userAgent.toLowerCase()

  useEffect(() => {
    const checkDeviceType = () => {
      const userAgent = navigator.userAgent.toLowerCase()
      const mobileDevices =
        /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i

      setIsMobile(mobileDevices.test(userAgent))
      console.log('Is Mobile:', isMobile)
    }

    checkDeviceType()
  }, [])

  useEffect(() => {
    console.log('isMobile updated:', isMobile)
  }, [isMobile])

  const mobileMainPage = window.location.href.includes('mobile-main')
  const newMainPage = window.location.href.includes('new-main')

  const showMobileHeader = mobileMainPage || isMobile

  return (
    <Container>
      {/* {showMobileHeader ?   <MobileHeader /> : <Header />} */}
      {/* <MobileHeader /> */}
      {showMobileHeader ? (
        <MobilePage />
      ) : (
        <>
          <Header />
          {newMainPage && <Samsung />}
          <Section01 />
          <Section02 />
          <Section03 />
          <Section05 />
          <FooterInLogo />
          <div>isMobile {isMobile.toString()}</div>
          <div>{userAgent}</div>
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  scroll-behavior: auto;
  width: 100%;
`

export default NewMainPage
