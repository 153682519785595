import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Colors } from '@styles/colors'
import MobilePage from '../../NewMobilePage/samsungEvent/index'

const Index = () => {
  const [isMobile, setIsMobile] = useState(false)
  const userAgent = navigator.userAgent.toLowerCase()

  useEffect(() => {
    const checkDeviceType = () => {
      const userAgent = navigator.userAgent.toLowerCase()
      const mobileDevices =
        /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i

      setIsMobile(mobileDevices.test(userAgent))
      console.log('Is Mobile:', isMobile)
    }

    checkDeviceType()
  }, [])

  useEffect(() => {
    console.log('isMobile updated:', isMobile)
  }, [isMobile])

  return (
    <Container>
      <CenterContainer>
        <Content>
          <TopContainer>
            <CrossBrand>일육공 X 삼성증권</CrossBrand>
            <Title>공모주 간편투자 제휴 이벤트</Title>
            <SubTitle>
              {`공모주 투자일임계약하고 신세계 모바일 상품권 최대 4만원 받으세요.`}
              <Guide>(미션 완료 시 · 선착순 5,000명)</Guide>
            </SubTitle>
          </TopContainer>
          <BottomContainer>
            <LeftContainer>
              <EventTarget>
                <ContentTitle>대상</ContentTitle>
                <Detail>
                  일육공에서 삼성증권 계좌개설 후 간편 투자 이용 고객
                </Detail>
              </EventTarget>
              <EventTerm>
                <ContentTitle>기간</ContentTitle>
                <Detail>2024. 9. 27 ~ 12. 31</Detail>
              </EventTerm>
            </LeftContainer>
            <RightContainer>
              <img
                src={'/img/samsung/samsung_gift.png'}
                alt='신세계 모바일 상품권'
              />
            </RightContainer>
          </BottomContainer>
          <Link to='/samsung-event'>
            <EventButton>
              이벤트 자세히 보기
              <img
                src={'/icons/arrow_right_samsung.svg'}
                alt='신세계 모바일 상품권'
              />
            </EventButton>
          </Link>
          <Caution>
            {`※ 투자전 설명 청취 및 상품설명서 필독
            ※ 이 금융상품은 예금자보호법에 따라 보호되지 않습니다.
            ※ 자산가격변동 등에 따른 원금손실(0~100%) 발생가능 및 투자자 귀속
            ※ 청약의 권유는 (예비·간이)투자설명서에 따름

            한국금융투자협회 심사필 제24-03913호 (2024-09-30 ~ 2025-09-29)
            삼성증권 준법감시인 심사필 제24-B3435호(2024.11.27 ~ 2025.09.29)`}
          </Caution>
        </Content>
      </CenterContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.bg_samsung};
  min-width: 1280px;
  padding: 72px 224px;
`
const Title = styled.div`
  color: #fff;
  font-family: 'GmarketSans';
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const CenterContainer = styled.div`
  margin-top: 79px;
`

const SubTitle = styled.p`
  color: #fff;
  font-family: 'GmarketSans';
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  gap: 12px;
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 1280px;
`

const RightContainer = styled.div`
  img {
    width: 376px;
    display: block;
  }
`

const LeftContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 23px;
`

const TopContainer = styled.div`
  border-bottom: 1px solid #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const ContentTitle = styled.div`
  color: #fff;
  font-size: 24px;
  font-style: normal;
  line-height: 150%;
  font-weight: 400;
  font-family: 'GmarketSans';
  opacity: 0.8;
`

const Detail = styled.div`
  color: #fff;
  font-size: 29px;
  font-style: normal;
  line-height: 150%;
  font-family: 'GmarketSans';
`

const BottomContainer = styled.div`
  display: flex;
  color: #fff;
  justify-content: space-between;
  padding: 32px 0px;
  box-sizing: border-box;
`

const EventButton = styled.div`
  padding: 18px 40px;
  background-color: #fff;
  border-radius: 50px;
  color: ${Colors.bg_samsung};
  text-align: center;
  display: flex;
  width: 380px;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-size: 32px;
  font-style: normal;
  line-height: 150%;
  font-family: 'GmarketSans';
  font-weight: bold;
`

const CrossBrand = styled.div`
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.14);
  width: 240px;
  height: auto;
  color: #fff;
  font-family: GmarketSans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

const Guide = styled.div`
  font-family: 'GmarketSans';
  opacity: 0.8;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 40px;
`

const EventTarget = styled.div`
  display: flex;
  flex-direction: column;
`
const EventTerm = styled(EventTarget)``

const Caution = styled.div`
  white-space: pre-line;
  color: #fff;
  font-size: 26px;
  font-style: normal;
  line-height: 130%;
  letter-spacing: -0.33px;
  opacity: 0.85;
  margin-top: 72px;
  font-family: Pretendard;
  opacity: 0.8;
`

export default Index
