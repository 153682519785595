import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Colors } from '@styles/colors'

interface ContainerProps {
  padding?: string
}

const Index = () => {
  return (
    <Container>
      <Section1>
        <Container_Min padding='72px 24px'>
          <CrossBrand>
            일육공
            <img src={'/img/download/X-w.svg'} alt='X' />
            삼성증권
          </CrossBrand>
          <Title>{`공모주 간편투자 \n 제휴 이벤트`}</Title>
          <GiftImg
            src={'/img/samsung/samsung_gift.png'}
            alt='신세계 모바일 상품권'
          />
        </Container_Min>
      </Section1>
      <Section2>
        <Container_Min padding='62px 24px'>
          <TopContainer>
            <Title_Section02>{`공모주 투자일임계약하고  \n 신세계 모바일 상품권 \n 최대 4만원 받으세요!`}</Title_Section02>
            <Content2>(미션 완료 시 * 선착순 5,000명)</Content2>
            <EventTarget>
              <TargetTitle>
                <ContentTitle>대상</ContentTitle>
                <Detail>
                  {`일육공에서 삼성증권 계좌개설 후 간편 투자 \n 이용 고객`}
                </Detail>
              </TargetTitle>
              <TargetTitle>
                <ContentTitle>기간</ContentTitle>
                <Detail>2024.9.27 ~ 12.31</Detail>
              </TargetTitle>
            </EventTarget>
          </TopContainer>
          <Link to='/samsung-event'>
            <EventButton>
              이벤트 자세히 보기
              <img
                src={'/icons/arrow_right_white_samsung.svg'}
                alt='신세계 모바일 상품권'
              />
            </EventButton>
          </Link>
        </Container_Min>
      </Section2>
      <Section3>
        <Container_Min padding='32px 24px'>
          <TopCaution>
            {`※ 투자전 설명 청취 및 상품설명서 필독
            ※ 이 금융상품은 예금자보호법에 따라 보호되지 않습니다.
            ※ 자산가격변동 등에 따른 원금손실(0~100%) 발생가능 및 투자자 귀속
            ※ 청약의 권유는 (예비·간이)투자설명서에 따름`}
          </TopCaution>
          <BottomCaution>
            {`한국금융투자협회 심사필 제24-03913호 (2024-09-30 ~ 2025-09-29)
            삼성증권 준법감시인 심사필 제24-B3435호(2024.11.27 ~ 2025.09.29)`}
          </BottomCaution>
        </Container_Min>
      </Section3>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin-top: 78px;
`

const ContentTitle = styled.div`
  font-family: 'GmarketSans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`

const Detail = styled.div`
  color: black;
  font-family: 'GmarketSans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  white-space: pre-line;
`

const EventTarget = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
  gap: 26px;
`

const Section1 = styled.div`
  height: auto;
  line-height: 32px;
  white-space: pre-line;
  color: white;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 32px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.bg_samsung};
`

const Container_Min = styled.div<ContainerProps>`
  min-width: 360px;
  padding: ${({ padding }) => padding || '0px 0px'};
`

const Content = styled.div`
  color: rgba(255, 255, 255, 0.9);
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
  letter-spacing: -0.36px;
`

const Mission = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  border-bottom: 1px solid #c5ccd6;
  padding: 16px 0px;
  box-sizing: border-box;

  &:last-child {
    border-bottom: none;
  }
`
const Title = styled.div`
  font-family: 'GmarketSans';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: pre-line;
  margin-top: 8px;
`

const TargetTitle = styled.div``

const Title_Section02 = styled.div`
  font-family: 'GmarketSans';
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: pre-line;
  color: black;
`

const Section2 = styled(Section1)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: white;
  width: 100%;
`

const SubTitle = styled.div`
  color: #0e6dfb;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

const SubContent = styled.div`
  color: #555564;
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const MissionNumber = styled.div`
  color: var(--brand-color-2-0, #0e6dfb);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  padding: 4px 4px;
  border-radius: 4px;
  border: 1px solid #bae6fd;
  width: auto;
  justify-content: center;
  align-items: center;
  background-color: #e0f2fe;
  height: 27px;
`
const MissionContent = styled.div`
  color: var(--ui-color-text-dark, #555564);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 257px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Favor = styled.div`
  border-radius: 16px;
  width: 100%;
  height: auto;
  padding: 16px 16px;
  background-image: linear-gradient(159deg, #f0f9ff -1.66%, #5e9fff 126.18%);
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`

const Top = styled.div`
  color: #0e6dfb;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const BrandTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const GiftImg = styled.img`
  width: 312px;
  margin-top: 60px;
`

const Circle = styled.div`
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background-color: #0e6dfb;

  color: #fff;
  font-family: Pretendard;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`
const Bottom = styled.div`
  border-radius: 8px;
  border: 1px solid #d2eafa;
  padding: 16px 12px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
`

const TopCaution = styled.div`
  white-space: pre-line;
`
const BottomCaution = styled.div`
  white-space: pre-line;
  margin-top: 25px;
  font-weight: 500;
`

const Section3 = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #e8e8e8;
  gap: 13px;
  color: #808491;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
`

const Dot = styled.div`
  padding: 0px 4px;
  box-sizing: border-box;
`

const NoticeTitle = styled.div`
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`

const ContentContainer = styled.div`
  display: flex;
`

const Notice = styled.div`
  display: flex;
  flex-direction: column;
`

const NoticeContent = styled.div`
  color: #808491;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`

const HI = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`

const CrossBrand = styled.div`
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.14);
  width: 160px;
  height: 28px;
  color: #fff;
  font-family: 'GmarketSans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 146px;
  gap: 4px;
  color: #0e6dfb;
`
const BottomTitle = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
const BottomContent = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: pre-line;
`

const Sticker = styled.div`
  display: flex;
  border-radius: 8px;
  background-color: #e0f2fe;
  padding: 12px 16px;
  width: auto;
  color: #0e6dfb;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  width: 126.73px;
`

const SubTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const FCFS = styled.div`
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #999ba9;
`

const EventButton = styled.div`
  border-radius: 50px;
  background-color: ${Colors.bg_samsung};
  width: 100%;
  min-width: 312px;
  padding: 16px 24px;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  line-height: 150%;
  font-family: 'GmarketSans';
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 40px;
`

const Desc = styled.div`
  white-space: pre-line;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.33px;
  margin-top: 72px;
  opacity: 0.7;
  width: 312px;
`

const Content2 = styled.div`
  color: #fff;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 200;
  line-height: 150%;
  opacity: 0.8;
  margin-top: 4px;
  margin-bottom: 24px;
  color: #808080;
`

const BorderBox = styled.div`
  width: 100%;
  height: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #fff;
`

export default Index
